import parsePriceToNumber from '../parsePriceToNumber';

/* eslint-disable camelcase */
export const ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;
export const CONVERSION_ID = process.env.NEXT_PUBLIC_GOOGLE_CONVERSION_ID;
export const CONVERSION_LABEL = process.env.NEXT_PUBLIC_GOOGLE_CONVERSION_LABEL;

const search = () => {
   if (window.gtag) {
      window.gtag('event', 'search', {
         event_label: 'Búsqueda',
      });
   }
};

const pageview = (url) => {
   if (window.gtag) {
      window.gtag('event', 'page_view', {
         page_location: url,
      });
   }
};

const viewItem = (product) => {
   const { name, category, id, price, brand } = product;

   if (window.gtag) {
      window.gtag('event', 'view_item', {
         event_label: 'Ver producto',
         non_interaction: true,
         items: [
            {
               id,
               name,
               brand,
               category,
               price,
            },
         ],
      });
   }
};

const viewPromotion = (name) => {
   if (window.gtag) {
      window.gtag('event', 'view_promotion', {
         event_label: 'Ver landing',
         promotions: [{ name }],
      });
   }
};

const viewHome = () => {
   if (window.gtag) {
      window.gtag('event', 'view_home', {
         event_label: 'Ver Home',
      });
   }
};

const viewCart = () => {
   if (window.gtag) {
      window.gtag('event', 'view_cart', {
         event_label: 'Ir a carrito',
      });
   }
};

const beginCheckout = () => {
   if (window.gtag) {
      window.gtag('event', 'begin_checkout', {
         event_label: 'Proceder a la Compra',
      });
   }
};

const addPaymentInfo = () => {
   if (window.gtag) {
      window.gtag('event', 'add_payment_info', {
         event_label: 'Pagar',
      });
   }
};

const addShippingInfo = (method) => {
   if (window.gtag) {
      window.gtag('event', 'add_shipping_info', {
         event_label: 'Enviar informacion de envio',
         value: method,
      });
   }
};

const addToCart = (product) => {
   const { name, category, id, price, brand, variant, quantity } = product;

   if (window.gtag) {
      window.gtag('event', 'add_to_cart', {
         event_label: 'Agregar producto al carro',
         value: price,
         currency: 'ARS',
         items: [
            {
               name,
               category,
               id,
               price,
               brand,
               variant,
               quantity,
            },
         ],
      });
   }
};

const removeFromCart = (product) => {
   const { name, category, id, price, brand, variant, quantity } = product;

   if (window.gtag) {
      window.gtag('event', 'remove_from_cart', {
         event_label: 'Remover producto del carro',
         value: price,
         currency: 'ARS',
         items: [
            {
               name,
               category,
               id,
               price,
               brand,
               variant,
               quantity,
            },
         ],
      });
   }
};

const signup = () => {
   if (window.gtag) {
      window.gtag('event', 'sign_up', {
         event_label: 'Crear cuenta',
      });
   }
};

const purchase = (cart) => {
   const { number, line_items, total } = cart;

   const items = line_items.map((x) => ({
      name: x.name,
      category: x.category,
      id: x.sku,
      price: parsePriceToNumber(x.prices?.sale_price ?? x.prices.original),
      brand: x.brand,
      variant: x.variant,
      quantity: x.quantity,
   }));

   if (window.gtag) {
      window.gtag('event', 'purchase', {
         value: parsePriceToNumber(total),
         currency: 'ARS',
         event_label: 'Compra',
         transaction_id: number,
         affiliation: 'Vaypol',
         items,
      });
   }
};

const purchaseTry = () => {
   if (window.gtag) {
      window.gtag('event', 'purchase_try', {
         event_label: 'Intento de compra',
      });
   }
};

const conversion = (cart, phone, address) => {
   const { number, total, email } = cart;
   let user_data = { email, phone, address };
   user_data = Object.fromEntries(Object.entries(user_data).filter((v) => v[1] != null));
   if (window.gtag && CONVERSION_LABEL) {
      window.gtag('set', 'user_data', user_data);

      window.gtag('event', 'conversion', {
         send_to: CONVERSION_LABEL,
         value: parsePriceToNumber(total),
         currency: 'ARS',
         transaction_id: number,
      });
   }
};

export default {
   search,
   pageview,
   viewItem,
   viewPromotion,
   viewHome,
   viewCart,
   beginCheckout,
   addPaymentInfo,
   addToCart,
   removeFromCart,
   signup,
   purchase,
   purchaseTry,
   conversion,
   addShippingInfo,
};
