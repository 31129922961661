/* eslint-disable camelcase */
import analytics from '../analytics';
import fbpixel from '../fbpixel';
import gtm from '../googleTagManager';
import normalizeProduct from '../normalizeProduct';

const search = () => {
   analytics.search();
};

const pageview = (url) => {
   fbpixel.pageview();
   analytics.pageview(url);
   gtm.pageview(url);
};

const viewProduct = (args) => {
   const { productDetail, eventId } = args;
   if (productDetail) {
      const product = normalizeProduct(productDetail);

      fbpixel.viewContent(product, eventId);
      analytics.viewItem(product);
   }
};

const viewLanding = ({ eventID, landingInfo }) => {
   fbpixel.viewLanding(landingInfo, eventID);
   analytics.viewPromotion(landingInfo.name);
};

const viewHome = () => {
   analytics.viewHome();
};

const viewCart = () => {
   analytics.viewCart();
};

const addPaymentInfo = () => {
   analytics.addPaymentInfo();
};

const beginCheckout = () => {
   analytics.beginCheckout();
};

const addShippingInfo = (method) => {
   analytics.addShippingInfo(method);
};

const addToCart = (args) => {
   const { productDetail, eventId } = args;
   if (productDetail) {
      const product = normalizeProduct(productDetail);

      fbpixel.addToCart(product, eventId);
      analytics.addToCart(product);
   }
};

const removeFromCart = (product) => {
   // fbpixel.removeFromCart(product); en un futuro quizas lo quieran
   analytics.removeFromCart(product);
};

const signup = (eventId) => {
   fbpixel.completeRegistration(eventId);
   analytics.signup();
};

const subscribe = (eventId) => {
   fbpixel.subscribe(eventId);
   // analytics.subscribe(); en un futuro quizas lo quieran
};

const purchase = (args) => {
   const { cart, eventId } = args;
   fbpixel.purchase(cart, eventId);
   analytics.purchase(cart);
};

const purchaseTry = () => {
   analytics.purchaseTry();
};

const conversion = (cart) => {
   analytics.conversion(cart);
};

export default {
   search,
   pageview,
   viewProduct,
   viewLanding,
   viewHome,
   viewCart,
   addPaymentInfo,
   beginCheckout,
   addToCart,
   removeFromCart,
   signup,
   subscribe,
   purchase,
   purchaseTry,
   conversion,
   addShippingInfo,
};
