/* eslint-disable camelcase */
import axios from '../../services/axiosInstance';
import axiosSpsInstance from '../../services/axiosSpsInstance';
import { setCookie } from '../../services/cookies';
import errorHandler from '../error/handler';

const storeId = process.env.NEXT_PUBLIC_STORE_ID;

const getShippingAddress = async (loggedUser) => {
   try {
      const response = await axios.get(`/checkout/ship_address?code=${storeId}`, {
         params: { loggedUser },
      });

      return {
         success: true,
         data: response.data.data,
      };
   } catch (err) {
      const message = errorHandler(err);

      return {
         success: false,
         error: message,
      };
   }
};

const getBillingAddress = async (loggedUser) => {
   try {
      const response = await axios.get(`/checkout/bill_address?code=${storeId}`, {
         params: { loggedUser },
      });

      return {
         success: true,
         data: response.data.data,
      };
   } catch (err) {
      const message = errorHandler(err);

      return {
         success: false,
         error: message,
      };
   }
};

const getPhoneNumber = async (loggedUser) => {
   try {
      const response = await axios.get(`/checkout/phone?code=${storeId}`, {
         params: { loggedUser },
      });

      return {
         success: true,
         data: response.data.data,
      };
   } catch (err) {
      const message = errorHandler(err);

      return {
         success: false,
         error: message,
      };
   }
};

const updatePhoneNumber = async (phone) => {
   try {
      const response = await axios.patch(`/checkout?code=${storeId}`, {
         order: {
            user_profile_attributes: {
               phone,
            },
         },
      });

      return {
         success: true,
         data: response.data.data,
      };
   } catch (err) {
      const message = errorHandler(err);

      return {
         success: false,
         error: message,
      };
   }
};

const updateShipping = async (payload) => {
   try {
      const response = await axios.patch(`/checkout?code=${storeId}`, payload);
      return {
         success: true,
         data: response.data.data,
      };
   } catch (err) {
      const message = errorHandler(err);

      return {
         success: false,
         error: message,
      };
   }
};

const updateBilling = async (payload) => {
   try {
      const response = await axios.patch(`/checkout?code=${storeId}`, payload);
      return {
         success: true,
         data: response.data.data,
      };
   } catch (err) {
      const message = errorHandler(err);

      return {
         success: false,
         error: message,
      };
   }
};
const advanceToNext = async () => {
   try {
      const response = await axios.patch(`/checkout/next?code=${storeId}`);

      return {
         success: true,
         data: response.data.data,
      };
   } catch (err) {
      const message = errorHandler(err);

      return {
         success: false,
         error: message,
      };
   }
};

const advanceToPayment = async () => {
   try {
      const response = await axios.patch(`/checkout/advance?code=${storeId}`);

      if (response.data.data.attributes.state !== 'payment') {
         throw new Error('No se pudo continuar con el proceso de pago');
      }

      return {
         success: true,
         data: response.data.data,
      };
   } catch (err) {
      const message = errorHandler(err);

      return {
         success: false,
         error: message,
      };
   }
};

const tokenizeCard = async (payload) => {
   const { cardNumber, expDate, nameSurname, securityCode, device_unique_identifier, birthDate } =
      payload;

   const docNumber = payload.docNumber.replace(/\./g, '');
   const dateBirth = birthDate.replace(/\//g, '');
   const expMonth = expDate.split('/')[0];
   const expYear = expDate.split('/')[1];
   try {
      const response = await axiosSpsInstance.post('/tokens', {
         card_number: cardNumber,
         card_expiration_month: expMonth,
         card_expiration_year: expYear,
         security_code: securityCode,
         card_holder_name: nameSurname,
         card_holder_birthday: dateBirth,
         card_holder_identification: {
            type: 'dni',
            number: docNumber,
         },
         fraud_detection: {
            device_unique_identifier,
         },
      });

      // guardamos el spsToken en una cookie
      setCookie('spsToken', response.data.id);

      return {
         success: true,
         data: response.data,
      };
   } catch (err) {
      if (err.response) {
         err.response.data.errors = [
            {
               error_type: 'general',
               error_description: 'Ha ocurrido un error al validar la tarjeta',
            },
         ];
      }

      /* if (err.response.data.validation_errors[0].param === 'expired card') {
         err.response.data.error = 'La tarjeta ha expirado';
      } */

      const message = errorHandler(err);

      return {
         success: false,
         error: message,
      };
   }
};

const createPayment = async (payload) => {
   const {
      token,
      installmentId,
      bin,
      last_four_digits,
      device_unique_identifier,
      birthDate,
      methodId,
      amount,
      cartToken,
   } = payload;

   const dateBirth = birthDate.replace(/\//g, '');

   try {
      const response = await axios.patch(
         `/checkout?code=${storeId}`,
         {
            order: {
               payments_attributes: [
                  {
                     payment_method_id: methodId,
                  },
               ],
            },
            payment_source: {
               [methodId]: {
                  bin,
                  token,
                  card_promo_id: parseInt(installmentId, 10),
                  last_digits: last_four_digits,
                  device_unique_identifier,
                  card_holder_birthday: dateBirth,
                  amount,
               },
            },
         },
         { headers: { 'X-Spree-Order-Token': cartToken } },
      );

      return {
         success: true,
         data: response.data.data,
      };
   } catch (err) {
      const message = errorHandler(err);

      return {
         success: false,
         error: message,
      };
   }
};

const completePayment = async (eventId) => {
   try {
      const response = await axios.patch(`/checkout/complete?code=${storeId}&event_id=${eventId}`);

      return {
         success: true,
         data: response.data.data,
      };
   } catch (err) {
      let error_response =
         'Ha ocurrido un error en el pago. Intente nuevamente o llame al 0810 666 0945';
      if (err.response) {
         const { errors } = err.response.data;
         if (errors) {
            error_response = errors.map((x) => x.error_description).join(', ');
         }
      }
      return {
         success: false,
         data: error_response,
      };
   }
};

const unregisteredData = async (payload) => {
   try {
      const response = await axios.patch(`/checkout?code=${storeId}`, payload);
      return {
         success: true,
         data: response.data.data,
      };
   } catch (err) {
      const message = errorHandler(err);

      return {
         success: false,
         error: message,
      };
   }
};

const getUserData = async (email) => {
   try {
      const response = await axios.get(
         `${process.env.NEXT_PUBLIC_API_URL}/checkout/user_data?email=${email}&code=${storeId}`,
      );
      return {
         success: true,
         data: response.data.data,
      };
   } catch (err) {
      const message = errorHandler(err);

      return {
         success: false,
         error: message,
      };
   }
};
const createWalletPayment = async (walletName, amount) => {
   try {
      const wallets = { modo: 13 };
      const response = await axios.patch('/checkout/wallet/payment_intention', {
         payment_method_id: wallets[walletName],
         amount,
      });
      return {
         success: true,
         data: response.data,
      };
   } catch (err) {
      const message = errorHandler(err);

      return {
         success: false,
         error: message,
      };
   }
};
const addWalletPayment = async (paymentId, cartToken, paymentMethodId) => {
   try {
      const response = await axios.patch(
         '/checkout/wallet/add_wallet_payment',
         {
            payment_method_id: paymentMethodId,
            payment_id: paymentId,
            order_token: cartToken,
         },
         { headers: { 'X-Spree-Order-Token': cartToken } },
      );
      return {
         success: true,
         data: response.data,
      };
   } catch (err) {
      return {
         success: false,
         error: err,
      };
   }
};

const completeWalletPayment = async (eventId, cartToken) => {
   try {
      const response = await axios.patch(
         `/checkout/complete?code=${storeId}&event_id=${eventId}`,
         null,
         { headers: { 'X-Spree-Order-Token': cartToken } },
      );

      return {
         success: true,
         data: response.data.data,
      };
   } catch (err) {
      let error_response =
         'Ha ocurrido un error en el pago. Intente nuevamente o llame al 0810 666 0945';
      if (err.response) {
         const { errors } = err.response.data;
         if (errors) {
            error_response = errors.map((x) => x.error_description).join(', ');
         }
      }
      return {
         success: false,
         data: error_response,
      };
   }
};

export default {
   getShippingAddress,
   getBillingAddress,
   getPhoneNumber,
   getUserData,
   updatePhoneNumber,
   updateShipping,
   updateBilling,
   advanceToNext,
   advanceToPayment,
   tokenizeCard,
   createPayment,
   completePayment,
   unregisteredData,
   createWalletPayment,
   addWalletPayment,
   completeWalletPayment,
};
